// feature 1
.features-1 {
    .img-content {
        position: relative;

        &::before {
            content: "";
            background: linear-gradient(rgba($orange, 0.1) 0, rgba($orange, 0.15) 100%);
            width: 65%;
            height: 60%;
            position: absolute;
            bottom: 3rem;
            right: 5rem;
            z-index: -1;
            border-radius: 3rem;
            transform: rotate(20deg);
        }

        &::after {
            content: "";
            background: url('/assets/images/pattern/dot4.svg');
            width: 100%;
            height: 100%;
            position: absolute;
            top: -3rem;
            right: 3rem;
            z-index: -1;
        }

        .img-up {
            max-width: 80%;
        }
    }
    .icon-size{
        height: 38px;
        width: auto;
    }
}

@media (max-width: 768px) {
    .features-1 {
        .img-content {
            .img-up {
                max-width: 90%;
            }
        }
    }
}

.features-2 {
    .shapes {
        position: relative;

        .shape1 {
            position: absolute;
            top: 30%;
            right: 3%;
            background: lighten($primary, 10%);
            height: 0.6rem;
            width: 0.6rem;
            border-radius: 100%;
        }

        .shape2 {
            position: absolute;
            bottom: 20%;
            left: 3%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 10.4px 6px 0;
            border-color: transparent lighten($success, 10%) transparent transparent;
        }

        .shape3 {
            position: absolute;
            bottom: 5%;
            left: 5%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 10.4px 6px 0;
            border-color: transparent lighten($danger, 10%) transparent transparent;
        }

        .shape4 {
            position: absolute;
            top: 35%;
            right: 30%;
            background: lighten($warning, 10%);
            height: 0.6rem;
            width: 0.6rem;
            border-radius: 100%;
        }

        .shape5 {
            position: absolute;
            bottom: 5%;
            right: 5%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 10.4px;
            border-color: transparent transparent transparent lighten($warning, 10%);
        }

        .shape6 {
            position: absolute;
            bottom: 40%;
            left: 5%;
            width: 10px;
            height: 10px;
            background: rgba(0, 0, 0, 0);
            border-radius: 100%;
            border: calc(12px/8) solid lighten($primary, 20%);
            box-sizing: content-box
        }
    }
}


// features 3
.features-3 {
    .img-content {
        position: relative;

        &::before {
            content: "";
            background: url('/assets/images/pattern/dot5.svg');
            width: 5rem;
            height: 5rem;
            position: absolute;
            top: -2rem;
            left: -2rem;
            z-index: -1;
        }

        &::after {
            content: "";
            background: url('/assets/images/pattern/dot2.svg');
            width: 5rem;
            height: 5rem;
            position: absolute;
            bottom: -2rem;
            right: -2rem;
            z-index: -1;
        }
    }

    .img-content2 {
        position: relative;

        &::before {
            content: "";
            background: url('/assets/images/pattern/dot5.svg');
            width: 5rem;
            height: 5rem;
            position: absolute;
            top: -2rem;
            right: -2rem;
            z-index: -1;
        }

        &::after {
            content: "";
            background: url('/assets/images/pattern/dot2.svg');
            width: 5rem;
            height: 5rem;
            position: absolute;
            bottom: -2rem;
            left: -2rem;
            z-index: -1;
        }
    }
}

@media (max-width: 768px) {
    .features-3 {
        .img-content,
        .img-content2 {
            .img-up {
                margin: 0 auto;
                max-width: 90%;
            }
        }
        .img-content {
            &::after {
                right: 0rem;
            }
            &::before {
                left: 0rem;
            }
        }
        .img-content2 {
            &::after {
                left: 0rem;
            }
            &::before {
                right: 0rem;
            }
        }
    }
}


// features 4
.features-4 {
    .img-content {
        img {
            border: 10px solid $white;
        }

        &:after {
            content: "";
            background: linear-gradient(rgba($success, 0.1) 0, rgba($success, 0.15) 100%);
            position: absolute;
            border-radius: 70% 30% 76% 24% / 68% 30% 73% 22%;
            top: -20px;
            bottom: -20px;
            height: 120%;
            width: 70%;
            left: 0;
            right: 0;
            margin: auto;
            z-index: -1;
        }
    }

    .feature-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -100px;
    }
}

@media (max-width: 768px) {
    .features-4 {
        .feature-content {
            position: relative;
            top: 0;
            transform: none;
            left: 0;
        }
    }
}

// features -  5
.features-5 {
    .feature-item {
        &:hover {
            transition: box-shadow 0.25s ease-in-out 0.15s;
            box-shadow: $box-shadow-lg !important;
        }
    }
}

// features -  6
.features-6 {
    .feature-item {
        transition: $transition-base;
        position: relative;

        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba($white, 0.5);
            opacity: 0;
        }

        .btn-view {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            box-shadow: $box-shadow !important;

            .overlay {
                opacity: 1;
            }
        }
    }
}

// features - 7
.features-7 {
    .feature-item {
        .number {
            position: absolute;
            top: 0;
            z-index: -1;
            opacity: 0.3;
        }
    }
}

// features-8
.features-8 {
    .feature-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 400px;
        z-index: 1;

        .feature-text {
            position: relative;
            z-index: 1;
        }

        &::after {
            content: "";
            background: url('/assets/images/pattern/dot2.svg');
            width: 5rem;
            height: 5rem;
            position: absolute;
            bottom: -2rem;
            left: -2rem;
            z-index: -1;
        }
    }

    .feature-img {
        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($white, 0.25);
            z-index: 0;
        }

        &::before {
            content: "";
            background: url('/assets/images/pattern/dot5.svg');
            width: 5rem;
            height: 5rem;
            position: absolute;
            top: -2rem;
            right: -2rem;
            z-index: -1;
        }

        img {
            position: relative;
            z-index: -1;
            width: 85%;
        }
    }

    @media (max-width: 576px) {
        .feature-content {
            position: relative;
            top: 0;
            transform: none;
            max-width: 100%;
            &::after {
                left: -0.5rem;
            }
        }
        .feature-img {
            img {
                width: 100%;
            }
            &::before {
                right: -0.5rem;
            }
        }
    }
}

// features - 9
.features-9 {
    .feature-item {
        transition: all 0.25s ease-in-out 0.15s;

        .icon {
            border-bottom-left-radius: 0.75rem !important;
            border-top-right-radius: 0.75rem !important;
            transition: all 0.25s ease-in-out 0.15s;
        }

        &:hover {
            box-shadow: $box-shadow-lg !important;

            .icon {
                border-top-left-radius: 0.75rem !important;
                border-bottom-right-radius: 0.75rem !important;
                border-bottom-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }
    }
}