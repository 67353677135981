.radius{
  width: 540px;
  height: 540px;
  border-radius: 50%;
  text-align: center;
  @media (max-width: 575.8px) {
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
  }
  .radius-title{
    font-size: 2.6rem;
    line-height: 1.2;
    font-weight: $font-weight-semibold;
    color: #0767B0;
  }
  .img-container {
    position: relative;
    padding: 0.6rem;
    z-index: 1;
    min-width: 110%;
    img {
      position: relative;
      max-width: 120%;
      z-index: 1;
    }
  }
}
.vission{
  background-color: #FCF4ED;
}
.mission{
  background-color: #EDF6FD;
}
.our-team{
  text-align: center;
  .our-team-title{
    font-size: 2.6rem;
    line-height: 1.2;
    font-weight: $font-weight-semibold;
    color: #0767B0;
  }
}
.our-team-list-card{
  background-image: url("/assets/images/home/about-us/Ellipse.png"),url("/assets/images/home/about-us/Ellipse1.png") ;
  background-position: left center, right center;
  background-repeat: no-repeat;
  text-align: center;
}