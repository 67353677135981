.left-50 {
  left: 50%;
}
.fs-14px{
  font-size: 0.875rem;
  //line-height: 1.25rem;
}
.fs-16px{
  font-size: 1rem;
  //line-height: 1.75rem;
}
.fs-18px{
  font-size: 1.125rem;
  //line-height: 1.25rem;
}
.fs-20px{
  font-size: 1.125rem;
}
.fs-40px{
  font-size: 2.5rem;
  //line-height: 3.5rem;
}

.fs-48px{
  font-size: 3rem;
  //line-height: 4rem;
}

.fs-56px{
  font-size: 3.5rem;
  //line-height: 5rem;
}

.fs-64px{
  font-size: 4rem;
  //line-height: 4rem;
}
.p-1px{
  padding: 1px;
}

.hover-text-primary{
 :hover {
   color: #0767B0;
 }
}
.text-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.text-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.text-10-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* number of lines to show */
  line-clamp: 10;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.invisible-image {
  img {
    display: none;
  }
  .separator {
    a {
      display: none;
    }
  }
}

.height-fit-content {
  height: fit-content;
}

.img-cover {
  object-fit: cover;
}

.text-shade-color {
  color: #454D5A
}

.text-branding-color {
  color: #0B1725;
}

.min-height-430px {
  min-height: 450px;
}

.min-height-550px {
  min-height: 550px;
}

.container-fluid{
  @media (min-width: 768px) {
    width: 95%;
  }
  @media (min-width: 992px) {
    width: 85%;
  }
  @media (min-width: 1200px) {
    width: 80%;
  }
}