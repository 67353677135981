.categories {
  background-color: #F8FBFE;
  border-radius: 4px;
}

.text-color {
  color: #0767B0;
}

.text-category {
  font-size: 14px;
  font-weight: 400;
  color: #A2ABBA !important;
}

.card-img-top {
  height: 250px;
  border-radius: 4px;
}

.line-list {
  border-bottom: 1px solid #EDF6FD;
}

