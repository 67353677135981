.banner-sevices{
  background-image: url("/assets/images/banner/Banner.png");
  background-repeat: no-repeat;
  text-align: center;
  background-position: right;
  background-size: cover;
  .banner-title{
    font-size: 4rem;
    line-height: 4rem;
    font-weight: $font-weight-bold;
    color: #FFFFFF;
    @media (max-width: 768px) {
      font-size: 2.9rem;
      line-height: 1.1;
    }
    @media (max-width: 576px) {
      font-size: 2.4rem !important;
    }
  }
  .banner-link{
    font-size: 1rem;
    line-height: 1.75rem;
    color: #FFFFFF;
    .banner-btn{
      color: #FFFFFF;
    }
  }
}
.banner-about-us{
  background-image: url("https://mmp.peacom.co/media/illustrations/sketchy-1/pcg-background.jpeg");
  background-repeat: no-repeat;
  text-align: center;
  background-position: right;
  background-size: cover;
  .banner-title{
    font-size: 4rem;
    line-height: 4rem;
    font-weight: $font-weight-bold;
    color: #112C43;
    @media (max-width: 768px) {
      font-size: 2.9rem;
      line-height: 1.1;
    }
    @media (max-width: 576px) {
      font-size: 2.4rem !important;
    }
  }
  .banner-link{
    font-size: 1rem;
    line-height: 1.75rem;
    color: #112C43;
    .banner-btn{
      color: #112C43;
    }
  }
}
.banner-product{
  background-image: url("/assets/images/banner/product_banner.png");
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  background-size: cover;
  .banner-title{
    font-size: 4rem;
    line-height: 4rem;
    font-weight: $font-weight-bold;
    color: #112C43;
    @media (max-width: 768px) {
      font-size: 2.9rem;
      line-height: 1.1;
    }
    @media (max-width: 576px) {
      font-size: 2.4rem !important;
    }
  }
  .banner-link{
    font-size: 1rem;
    line-height: 1.75rem;
    color: #112C43;
    .banner-btn{
      color: #112C43;
    }
  }
}