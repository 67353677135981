
.about-us-review{
  background-color: #EDF6FD;
  background-image: url("/assets/images/home/about-us/pattern-full.png"),url("/assets/images/home/about-us/pattern-separate.png") ;
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 1rem;
  p {
    overflow: hidden;
    text-overflow: ellipsis;

  }
  .about-us-review-title-1{
    font-weight: $font-weight-semibold;
    color: #0767B0;
    @media (max-width: 768px) {
      font-size: 1.9rem;
      line-height: 1.1;
    }
    @media (max-width: 576px) {
      font-size: 1.4rem !important;
    }
  }
  .about-us-review-btn{
    color: #0767B0;
  }
}
.our-service-review{
  background-image: url("/assets/images/home/our-service/Ellipse.png");
  background-repeat: no-repeat;
  background-position: left;
  h1{
    color: #0767B0;
  }
  h4{
    color: #0767B0;
  }
  .hero-right {
    .img-container {
      position: relative;
      padding: 0.6rem;
      z-index: 1;
      img {
        position: relative;
        z-index: 1;
      }
    }
  }
}

.our-product-review{
  background-image: url("/assets/images/home/our-product/Ellipse.png");
  background-repeat: no-repeat;
  background-position: right;
  h1{
    color: #0767B0;
  }
  h4{
    color: #0767B0;
  }
  .hero-right {
    .img-container {
      position: relative;
      padding: 0.6rem;
      z-index: 1;
      img {
        position: relative;
        z-index: 1;
      }
    }
  }
}
.product-general-information{
  background-image: url("/assets/images/home/our-product/pattern-separate.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: #0767B0;
  h1{
    font-size: 2.6rem;
    line-height: 1.4;
    font-weight: $font-weight-semibold;
    color: #FFFFFF;
  }
  h4{
    color: #FFFFFF;
  }
}
.clients-review-title-1{
  color:#0767B0;
}